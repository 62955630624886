@font-face {
    font-family: 'Axiforma';
    font-style: normal;
    font-weight: 200;
    src: url('./assets/fonts/Axiforma-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/Axiforma-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/Axiforma-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/Axiforma-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/Axiforma-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/Axiforma-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-style: normal;
    font-weight: 800;
    src: url('./assets/fonts/Axiforma-Heavy.ttf') format('truetype');
}


.background {
    background-color: #23282a;
    width: 100vw;
    height: 100vh;
    display: flex;
}

.boxLeft {
    background-color: #23282a;
    width: 55vw;
    height: 95vh;
    display: flex;
    overflow-x: hidden;
    flex-wrap: nowrap;
    margin: 2.5vh 1vw 2.5vh 2vw;
    justify-content: center;
    border-radius: 10px;
}

.boxRight {
    background-color: #ffff;
    width: 39vw;
    height: 95vh;
    margin: 2.5vh 2vh 2.5vh 1vw;
    border-radius: 10px;
}

.logoBlackay{
    margin-top: 30px;
    width: auto;
    height: 60px;
}

h2 {
    font-family: 'Axiforma', sans-serif;
    margin: 30px auto;
    color: #000000;
    text-align: center;
    font-weight: 800;
    font-size: 30px;
    max-width: 600px;
    position: relative;
}


h3 {

    font-family: 'Axiforma', sans-serif;
    margin: 40px auto;
    color: #000000;
    text-align: center;
    font-size: 30px;
    font-weight: 800;
    max-width: 600px;
    position: relative;
}

/* Fija el botón Add User en la esquina superior derecha */
.add-user-button {
    position: fixed;
    top: 10px;
    right: 10px;
}

.alert-label {
    font-family: 'Axiforma', sans-serif;
    font-size: 2rem;
    font-weight: 500;
}

.score-counter {

    width: auto;
    height: auto;
    margin: auto;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.flippy-front,
.flippy-back {
    background: #23282a !important;
    color: #fff;
    border-radius: 20px;
    padding: 0 !important;
    height: 300px !important;
    width: 400px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 10rem;
    font-weight: bold;
    transform: rotateY(180deg);
}

.red {
    color: red;
}

.tablaRanking {
    width: 39vw;
    text-align: -webkit-center;
}

table {
    border-collapse: collapse;
    width: 85%;
}

th,
td {
    text-align: center;
    padding: 8px;
}

th {
    background: linear-gradient(to bottom,
            #ffd437 0%,
            #ffd437 50%,
            #d2ac32 50%,
            #d2ac32 100%);
    font-family: 'Axiforma', sans-serif;
    font-weight: 800;
    font-size: larger;
    height: 40px !important;
    transition: all 0.5s ease;
}

.rowtr {
    font-family: 'Axiforma', sans-serif;
    background-color: #23282a;
    color: #fff;
    border: solid;
    border-width: 10px;
    border-color: #ffff;
    transition: all 0.5s ease;
}

tr {
    transition: all 0.5s ease;
}

/* tr:nth-child(even) {
    background-color: #23282a;
    color: #fff;
} */

button {
    font-family: 'Axiforma', sans-serif;
    background-color: #ffd437;
    border: none;
    font-weight: bold;
    color: rgb(0, 0, 0);
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
}

button:hover {
    background-color: #d2ac32;
}

input[type="text"] {
    font-family: 'Axiforma', sans-serif;
    padding: 6px;
    border-radius: 4px;
    border: none;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

form {
    font-family: 'Axiforma', sans-serif;
    margin-bottom: 16px;
    border-radius: 10px;
}


.add-user-button form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.add-user-button form label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.add-user-button form input {
    padding: 10px;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.add-user-button form button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    background-color: #ffd437;

    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.add-user-button form button:last-of-type {
    background-color: #ccc;
    color: #000;
}